import { graphql, PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { InSkipInfo } from '../../components/FunnelSelector/SelectorSteps'
import Layout from '../../components/Layout'
import ResponsiveSliderBanner from '../../components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../components/Title'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { reset } from '../../context/actions/selectorActions'
import { selectSkipInfo } from '../../context/selectors/selectorSelectors'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { locale, t } from '../../i18n'
import { getImages, ImageNode } from '../../utils/imagesS3'
import route from '../../utils/route'
import styles from './marca.module.scss'
import withMarcas from '../../components/withMarcas'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import ModelosDescatalogados from './inc/ModelosDescatalogados'
import {
  getNeumaticosActivosAndDescatalogados,
  mapModelosMotoWithUrlImage,
  motoModelosGamaAndTipoReducer,
  reducedMotoModelosSorter,
  s3Url,
} from '../../components/LandingMarca/utils'
import { IAppState } from '../../context/context'
import MarcaCocheMotoSelector from '../../components/LandingMarca/marcaCocheMotoSelector'
import CardTireModel, {
  ICardTireModelo,
} from '../../components/CardTireModel/CardTireModel'
import { FEATURE_FLAGS, isFeatureEnabled } from '../../utils/featureFlag'

interface Props extends PageProps<Queries.LandingMarcaMotoPageQuery> {
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

interface SeccionNeumaticoPorTipoProps {
  marca: string
  logoMarca: string
  neumaticos: ICardTireModelo[]
  tipo?: string
}

const SeccionNeumaticoPorTipo = ({
  marca,
  logoMarca,
  neumaticos,
  tipo,
}: SeccionNeumaticoPorTipoProps) => {
  return (
    <div key={tipo} className="grupo-tipo">
      <CardTireModel
        marca={marca}
        logoMarca={logoMarca}
        neumaticos={neumaticos}
        title={tipo && _.startCase(_.toLower(tipo.toLowerCase()))}
      />
    </div>
  )
}

const LandingMarcasMoto = ({
  dispatch,
  data: {
    marcaInfo,
    modeloInfo,
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_pt_desktop,
    banners_es_mobile,
    banners_ca_mobile,
    banners_pt_mobile,
  },
}: Props) => {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    marca,
    descripcion_marca_moto,
    logoMarca,
    mas_info_moto,
    id_site_marca_neumatico,
    coche,
    moto,
  } = marcaInfo

  const { modelos } = modeloInfo
  const { neumaticosActivos, neumaticosDescatalogados } =
    getNeumaticosActivosAndDescatalogados(modelos)
  const sortedReducedModelos = reducedMotoModelosSorter(
    motoModelosGamaAndTipoReducer(mapModelosMotoWithUrlImage(neumaticosActivos))
  )

  const bannersDesktop = {
    es: banners_es_desktop.nodes,
    ca: banners_ca_desktop.nodes,
    pt: banners_pt_desktop.nodes,
  }

  const bannersMobile = {
    es: banners_es_mobile.nodes,
    ca: banners_ca_mobile.nodes,
    pt: banners_pt_mobile.nodes,
  }

  const bannerImagesDesktop = getImages(
    bannersDesktop[locale].map((banner: ImageNode) => banner),
    locale
  )
  const bannerImagesMobile = getImages(
    bannersMobile[locale].map((banner: ImageNode) => banner),
    locale
  )

  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        origen:
          typeof document !== 'undefined' ? document.location.pathname : '/',
        tipoVehiculo: null,
        temporada: {
          selectable: isFeatureEnabled(FEATURE_FLAGS.NEUMATICOS_MOTO),
          value: 'verano',
        },
        marca: id_site_marca_neumatico,
      })
    )
  }, [])

  const neumaticosTopVentas = mapModelosMotoWithUrlImage(
    neumaticosActivos.filter((neum) => neum.top_ventas)
  )

  const logoUrl = require(`!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../assets/images/logo_marcas/${logoMarca}.svg`)

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
        },
        {
          display: t('cita_previa.servicios.moto'),
        },
      ]}>
      <Helmet>
        <link rel="preload" href={logoUrl.default} />
        {['rodi', 'elpaso'].includes(process.env.GATSBY_WEB) ? (
          <meta
            property="og:image"
            content={`${s3Url}/marca/${marca.toLowerCase()}/neumaticos-${marca.toLowerCase()}.jpg `}
          />
        ) : null}
      </Helmet>
      <section className={styles.ficha_marca}>
        <div className={styles.fichaMarcaContent}>
          <Title text={t('ficha-marca.neumatico_moto', { marca })}>
            <img
              src={logoUrl.default}
              alt={marca}
              className={styles.logo}
              loading="eager"
            />
          </Title>
          {coche && moto && isFeatureEnabled(FEATURE_FLAGS.NEUMATICOS_MOTO) ? (
            <MarcaCocheMotoSelector marca={marca} />
          ) : null}

          {bannerImagesDesktop && (
            <ResponsiveSliderBanner
              sizesVariant="banner_neumaticos"
              desktopImages={bannerImagesDesktop}
              mobileImages={bannerImagesMobile}
            />
          )}

          <section className={styles.descripcion_marca}>
            {descripcion_marca_moto}
          </section>

          {!!neumaticosTopVentas.length && (
            <section className={styles.productos}>
              <h2 className={styles.title_gama}>
                {t('ficha-marca.neumaticos_moto_top_ventas', { marca })}
              </h2>
              <SeccionNeumaticoPorTipo
                marca={marca}
                logoMarca={logoMarca}
                neumaticos={neumaticosTopVentas}></SeccionNeumaticoPorTipo>
            </section>
          )}

          <h2 className={styles.title_gama}>
            {t('ficha-marca.gama_moto', { marca })}
          </h2>
          {sortedReducedModelos.map((gamaModelos) => (
            <SeccionNeumaticoPorTipo
              key={gamaModelos.gama}
              marca={marca}
              logoMarca={logoMarca}
              neumaticos={gamaModelos.modelos}
              tipo={gamaModelos.gama}></SeccionNeumaticoPorTipo>
          ))}
          <div
            className={styles.descripcion_marca}
            dangerouslySetInnerHTML={{ __html: mas_info_moto }}></div>
          {neumaticosDescatalogados.length ? (
            <section className={styles.descatalogados_section}>
              <div className={styles.descatalogados_content}>
                <ModelosDescatalogados
                  title={t('ficha-marca.descatalogados_moto_title', { marca })}
                  neumaticos={neumaticosDescatalogados}
                  marca={marca}
                />
              </div>
            </section>
          ) : null}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query LandingMarcaMotoPage(
    $idMarca: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $banners_pt_desktop: String
    $banners_pt_mobile: String
  ) {
    marcaInfo: neumaticosMarca(id_site_marca_neumatico: { eq: $idMarca }) {
      ...marcaInfo
    }
    modeloInfo: allModelosMoto(
      filter: { id_site_marca_neumatico: { eq: $idMarca } }
    ) {
      modelos: nodes {
        ...modelosMotoMarca
      }
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: $banners_pt_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: $banners_pt_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`

export default connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(withMarcas(LandingMarcasMoto))
